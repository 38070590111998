import { render, staticRenderFns } from "./gastroJournalSubscriptionForward.vue?vue&type=template&id=40aa2cb7&"
import script from "./gastroJournalSubscriptionForward.vue?vue&type=script&lang=ts&"
export * from "./gastroJournalSubscriptionForward.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports